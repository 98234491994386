//======> Tornado UI Core Base <=======//
import './Base/Tornado';
import DataTable from './Base/DataTable';
import Tornado from './Base/Tornado';

document.addEventListener('DOMContentLoaded', event => {
    "use strict";
    //====> Parent Toggle <====//
    Tornado.getElements('.parent-toggle').forEach(btn => {
        btn.addEventListener('click', event => {
            event.preventDefault();
            btn.parentNode.classList.toggle('activated');
        });
    });
    //====> Activate Data Tables <====//
    Tornado.getElements('.data-table')?.forEach(table => {
        let options = {
            perPage  : table.getAttribute('data-items') || 10,
        };
        
        DataTable(table, options);
    });

    //======> Tables Select Rows <======//
    Tornado.liveEvent('.select-all-rows','change', event => {
        //=====> Event Target <=====//
        let the_target = event.target,
            element = event.target as Element,
            theTable = Tornado.parentsUntil(element, '.table');
        //=====> When Checked <=====//
        if (the_target.checked) {
            theTable.querySelectorAll('.row-checkbox').forEach(element => element.checked = true);
        } else {
            theTable.querySelectorAll('.row-checkbox').forEach(element => element.checked = false);
        }
    });
});